/* --------------------------------------------------------------------
Import accessibility helpers
--------------------------------------------------------------------*/
import { addTabIndex, removeTabIndex  } from './accessibility.js';
import { swapClasses  } from './tools.js';

// Global
global.menuOpen = false;

/* --------------------------------------------------------------------
Handle Burger Menu
--------------------------------------------------------------------*/
const bodyTag = document.getElementsByTagName('body');
const openNavBtn = document.querySelectorAll('.burger_btn');
const navigation = document.querySelectorAll('.navigation');

if (navigation && openNavBtn) {
    /* ------------------------------------------------------------
    Update Focus state
    ------------------------------------------------------------ */
    openNavBtn[0].addEventListener("click", function(){
        toogleNavMenu();
    });

     window.addEventListener("resize", function(){
        if( window.outerWidth > 1023 ){
            if(navigation[0].classList.contains('duration-300') ){
                swapClasses( navigation[0], 'duration-300', 'duration-0');
            }
        }
        else{
            if(navigation[0].classList.contains('duration-300') ){
                swapClasses( navigation[0], 'duration-300', 'duration-0');
            }
        }
    });

    function toogleNavMenu(){

        var bars = openNavBtn[0].querySelectorAll('span');
        openNavBtn[0].classList.toggle('open');

        if(bars.length == 2){
            if(bars[0] && bars[1]){
                if ( !global.menuOpen ) {
                    bars[0].classList.add('animate-burgertop');
                    bars[1].classList.add('animate-burgerbottom');
                }
                else{
                    bars[0].classList.remove('animate-burgertop');
                    bars[1].classList.remove('animate-burgerbottom');

                    window.setTimeout(function(){

                        bars[0].classList.add('animate-burgertop_reverse');
                        bars[0].addEventListener("animationend", () => {
                            bars[0].classList.remove('animate-burgertop_reverse');
                        });

                        bars[1].classList.add('animate-burgerbottom_reverse');
                        bars[1].addEventListener("animationend", () => {
                            bars[1].classList.remove('animate-burgerbottom_reverse');
                        });
                    }, 1);
                }
            }
        }

        if(navigation[0].classList.contains('-translate-y-full') ){
            bodyTag[0].classList.add('menu-open');
            global.menuOpen = true;

            // Aria Label Toggle
            openNavBtn[0].ariaLabel = "Close Navigation Menu";

            // Set Tab Indexes
            removeTabIndex('tabbablelink');
        }
        else{
            bodyTag[0].classList.remove('menu-open');
            global.menuOpen = false;

            // Aria Label Toggle
            openNavBtn[0].ariaLabel = "Open Navigation Menu";

            // Set Tab Indexes
            addTabIndex('tabbablelink');
        }

        if(navigation[0].classList.contains('duration-0') ){
            swapClasses( navigation[0], 'duration-0', 'duration-300');
        }

        /* Toogle Classes on globally */
        swapClasses( navigation[0], '-translate-y-full', 'translate-y-0');
    }

    /* ------------------------------------------------------------
    List for key board input
    • If ESC key pressed, close menu
    ------------------------------------------------------------ */
    document.addEventListener("keyup", (e) => {
        if(global.menuOpen){
            if (e.key === "Escape") {
                toogleNavMenu();
            }
        }
    });
}
else{
    console.warn('Navigation or Burger Element missing.');
}