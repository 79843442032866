import '../css/app.pcss';

// Import Fonts
import '../fonts/GerminabuntRegular.woff';
import '../fonts/GerminabuntRegular.woff2';

import '../fonts/KlinicSlab-Bold.woff';
import '../fonts/KlinicSlab-Bold.woff2';

import '../fonts/LHF-Ephemera.woff';
import '../fonts/LHF-Ephemera.woff2';

import '../fonts/LHF-Hensler.woff';
import '../fonts/LHF-Hensler.woff2';

import '../fonts/IntelOneMono-Regular.woff';
import '../fonts/IntelOneMono-Regular.woff2';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

console.log('[WEB-APP] Javascript has been loaded.');

// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}