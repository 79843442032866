import { condenseHeader, uncondenseHeader } from './tools.js';

const bodyTag = document.body;

/* Anchor links with the anchor class to the assigned target */
const anchors = document.querySelectorAll('.anchor');

for (const anchor of anchors) {
    anchor.addEventListener('click', function(e){
        e.preventDefault();

        const link = e.target;
        const anchorTarget = link.hash.replace('#','');

        if (anchorTarget) {
            const header = document.querySelector('.hdr');
            const destination = document.getElementById(anchorTarget);
            const additionalPadding = 20;

            if (destination) {
                const scrollToOptions = {
                    top: destination.offsetTop - (header.offsetHeight + additionalPadding),
                    left: 0,
                    behavior: 'smooth'
                };
                document.body.parentNode.scrollTo(scrollToOptions);
            } else {
                console.error(`The link [${link}] has an anchor class but the href is not a valid hash.`);
            }
        }
    });
}
